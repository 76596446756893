import React, { createContext, useState } from 'react'

const Context = createContext({});

export { Context }

export default function LeaveContext({ children }) {
  const [state, setState] = useState({
    stepOneComplete: false,
    stepTwoComplete: false,
    stepThreeComplete: false,
    stepFourComplete: false,
  })

  return (
    <Context.Provider value={{
      ...state,
      stepOne: (memberData) => {
        setState(prev => ({
          ...prev,
          Member: {
            ...prev.Member,
            ...memberData,
          },
          stepOneComplete: true,
          stepTwoComplete: false,
          stepThreeComplete: false,
          stepFourComplete: false,
        }))
      },
      stepTwo: (contactData) => {
        setState(prev => ({
          ...prev, Contact: { ...prev.Contact, ...contactData }, stepTwoComplete: true, stepThreeComplete: false, stepFourComplete: false,
        }))
      },
      stepThree: (leaveRequestData) => {
        setState(prev => ({
          ...prev, LeaveRequest: { ...prev.LeaveRequest, ...leaveRequestData }, stepTwoComplete: false, stepThreeComplete: true, stepFourComplete: false,
        }))
      },
      stepFour: (ID, LeaveRequestNumber) => {
        setState(() => {
          if (!ID || !LeaveRequestNumber) {
            return {
              stepOneComplete: false, stepTwoComplete: false, stepThreeComplete: false, stepFourComplete: false,
            }
          }
          return {
            ID, LeaveRequestNumber, stepOneComplete: false, stepTwoComplete: false, stepThreeComplete: false, stepFourComplete: true,
          }
        })
      },
      fetchData: (data) => {
        setState(prev => ({ ...prev, ...data }))
      },
      fetchLeaveRequests: (OpenLeaveRequests) => { setState(prev => ({ ...prev, OpenLeaveRequests })) },
      ErrorMessage: (error) => {
        setState((prev) => {
          let ErrorMessage = error
          if (!ErrorMessage) ErrorMessage = 'Error in submitting your request.'
          return { ...prev, ErrorMessage }
        })
      },
      LogOut: () => {
        setState({
          stepOneComplete: false, stepTwoComplete: false, stepThreeComplete: false, stepFourComplete: false,
        })
      },
    }}
    >
      {children}
    </Context.Provider>
  )
}
