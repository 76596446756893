import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export default function StaticHelmet({ children }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    query StaticHelmetComponent {
      site {
        siteMetadata {
          pathPrefix
          webchatID
        }
      }
    }
  `)
  const { pathPrefix, webchatID } = data.site.siteMetadata

  return (
    <>
      <Helmet>
        <link href={`${pathPrefix}/styles/Structure.css`} rel="stylesheet" type="text/css" />
        <link href={`${pathPrefix}/styles/Styles.css`} rel="stylesheet" type="text/css" />
        <link href={`${pathPrefix}/styles/font-awesome.min.css`} rel="stylesheet" type="text/css" />
        <link href={`${pathPrefix}/styles/atooltip.css`} rel="stylesheet" type="text/css" />
        <link href={`${pathPrefix}/styles/colorbox.css`} rel="stylesheet" />
        <link href={`${pathPrefix}/styles/popups.css`} rel="stylesheet" type="text/css" />
        <script src={`${pathPrefix}/scripts/jquery-1.8.3.min.js`} type="text/javascript" />
        <script src={`${pathPrefix}/scripts/jquery.atooltip.js`} type="text/javascript" />
        <script src={`${pathPrefix}/scripts/jquery.colorbox-min.js`} type="text/javascript" />
        <script
          id="cbc_chat_script"
          type="text/javascript"
          webchat-id={webchatID}
          button=".ChatFooterBTN"
          src="https://webchat.cbcins.com/static/cbc_webchat.js"
        />
      </Helmet>
      {children}
    </>
  )
}
