import React, { createContext, useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';

const Context = createContext({
  login: () => {},
  forceChangePassword: () => {},
  toggleForceChangePassword: false,
  changePassword: () => {},
  logout: () => {},
  checkForCookie: () => {},
});

export { Context }

export default function UserContext({ children }) {
  const [state, setState] = useState({})
  const data = useStaticQuery(graphql`
    query UserContextComponent {
      site {
        siteMetadata {
          cookieName
        }
      }
    }
  `)
  const { cookieName } = data.site.siteMetadata
  useLayoutEffect(() => {
    const cookies = new Cookies();
    const oldCookie = cookies.get(cookieName, { path: '/' })
    if (oldCookie && oldCookie.token) {
      // Axios configuration
      axios.defaults.headers.common.Authorization = `Bearer ${oldCookie.token}`;

      setState(prev => ({
        ...prev,
        token: oldCookie.token,
        maxAge: oldCookie.maxAge,
      }))
    } else {
      delete axios.defaults.headers.common.Authorization;
      cookies.remove(cookieName, { path: '/' })
      setState(prev => ({ ...prev, token: null }))
    }
  }, [cookieName])

  return (
    <Context.Provider value={{
      ...state,
      login: (token, maxAge, Username, Password) => {
        const cookies = new Cookies();
        // Axios configuration
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        // Set up the cookie
        cookies.set(cookieName, { token, maxAge: moment().utc().add(maxAge, 'seconds') }, { path: '/', maxAge })
        setState({
          token, maxAge: moment().utc().add(maxAge, 'seconds'), Username, Password,
        })
      },
      forceChangePassword: () => {
        // only change
        setState(prev => ({ ...prev, toggleForceChangePassword: true }))
      },
      changePassword: (Password) => { setState(prev => ({ ...prev, Password, toggleForceChangePassword: false })) },
      logout: () => {
        const cookies = new Cookies();
        delete axios.defaults.headers.common.Authorization;
        cookies.remove(cookieName, { path: '/' })
        setState(prev => ({ ...prev }))
      },
    }}
    >
      {children}
    </Context.Provider>
  )
}
